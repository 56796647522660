import React, { useState } from 'react'
import Modal from 'react-modal'
import ImageUploading from 'react-images-uploading'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { some } from 'lodash/collection'
import { keys, pickBy } from 'lodash/object'
import { capitalize } from 'lodash/string'
import { isNull, isEmpty } from 'lodash/lang'
import { withTheme } from 'styled-components'
import { Store } from '@styled-icons/material'
import { VStack } from '../../../global/styles'
import TextInput from '../../../components/UI/Inputs/TextInput'
import Button from '../../../components/UI/Buttons/Button'
import {
  BUTTONS_SIZES,
  BUTTON_VARIANTS,
  INPUT_SIZES
} from '../../../const/UIvariants'
import TextAreaInput from '../../../components/UI/Inputs/TextAreaInput'
import SelectManyInput from '../../../components/UI/Inputs/SelectManyInput'
import {
  useGetShopDetailsQuery,
  usePutShopDetailsMutation
} from '../../../api/shopApi'
import LoadingSpinner from '../../../components/LoadingSpinner'
import SwitchInput from '../../../components/UI/Inputs/SwitchInput'
import {
  DEFAULT_CLOSE_TIME,
  DEFAULT_OPEN_TIME,
  WEEK_DAYS
} from '../../../const/app'
import EditWorkingHours from '../../../components/EditWorkingHours'
import { getErrorMessage, getImageFromServer, getImageBase64 } from '../../../utils/helpers'
import ShopOperationHoursModal from '../ShopOperationHoursModal'
import { useGetCategoriesQuery } from '../../../api/generalApi'
import Typography from '../../../components/UI/Typography'
import { globalTheme } from '../../../global/theme'
import { shopDetailsFormAdapter } from '../../../utils/adapters'
import SelectAddressInput from '../../../components/UI/Inputs/SelectAddressInput'
import ShopLogo from '../../../components/ShopLogo'
import ShowErrorModal from '../../../components/ShowErrorModal'
import { useGetHereTokenQuery, useGetSettingsQuery } from '../../../api/api.generated'
import {
  ModalBody,
  Headline,
  InputsWrapper,
  ImageButtonWrap,
  ButtonWrap,
  DividerLine,
  HoursWrapper,
  OpenHoursWrap,
  OperationalHoursErrorWrap,
  TextInputWrapper,
  ShopDetailsDescription,
  ShopDetailsHeadline,
  ShopDetailsHeadlineWrap,
  WorkingDayWrap,
  SwitchWrap,
  ErrorWrap,
  EmptyShopLogo,
  ImageRestrictions,
  ShopDetailsHead,
  ShopNameAndLogo,
  ShopNameAndAddress,
} from './styles'

const shopProperties = {
  shopName: 'shopName',
  shopDescription: 'description',
  shopAddress: 'address',
  geoData: 'geoData',
  shopEmail: 'email',
  shopCategories: 'categories',
  shopOpenDays: 'openDays',
  // open hours
  mondayOpen: 'mondayOpen',
  mondayClose: 'mondayClose',
  tuesdayOpen: 'tuesdayOpen',
  tuesdayClose: 'tuesdayClose',
  wednesdayOpen: 'wednesdayOpen',
  wednesdayClose: 'wednesdayClose',
  thursdayOpen: 'thursdayOpen',
  thursdayClose: 'thursdayClose',
  fridayOpen: 'fridayOpen',
  fridayClose: 'fridayClose',
  saturdayOpen: 'saturdayOpen',
  saturdayClose: 'saturdayClose',
  sundayOpen: 'sundayOpen',
  sundayClose: 'sundayClose',
  shopWebsite: 'website',
  onlineShopUrl: 'onlineShopUrl',
  shopPhone: 'phone',
  shopInstagram: 'instagram',
  shopTwitter: 'twitter',
  shopFacebook: 'facebook',
  isStoreEnabled: 'isStoreEnabled',
  isChatEnabled: 'isChatEnabled',
  showChatMessage: 'showChatMessage',
  chatMessage: 'chatMessage'
}
const EditShop = ({ theme, shopId, isOpen, onClose }) => {
  const [images, setImages] = useState([])
  const [logoRemoved, setLogoRemoved] = useState(false)
  const [selectedDay, setSelectedDay] = useState(null)
  const [error, setError] = useState('')
  const { data: shopDetails, isLoading, error: shopError } = useGetShopDetailsQuery(shopId)
  const { data: settings, isLoading: isLoadingSettings, error: settingsError } = useGetSettingsQuery()
  const { data: categories } = useGetCategoriesQuery()
  const [ putShopUpdate, { isLoading: isUpdating }] = usePutShopDetailsMutation()

  const customStyles = {
    content: {
      marginTop: '0',
    },
  };

 

  /*
  const handleDeleteLogo = (removeAll) => {
    setLogoRemoved(true)
    removeAll()
    dispatch(setShopLogotype(null))
  }

  const onChange = (image) => {
    setImages(image)
    if (getImageBase64({ source: image })) {
      dispatch(setShopLogotype(getImageBase64({ source: image })))
      setLogoRemoved(false)
      return
    }
    dispatch(setShopLogotype(null))
  }
  */

  const submitStoreChanges = (values) => {
    const image = values?.image?.includes('data:image')
      ? values.image
      : undefined

    putShopUpdate({
      shopId,
      data: shopDetailsFormAdapter(values, image)
    })
    .unwrap()
    .then(() => {
      setError('');
      onClose()
    })
    .catch((err) => {
      setError(getErrorMessage(err))
    })    
  }

  return (
    <Modal {...{ isOpen }} style={customStyles}>
      <ModalBody>
        {(isLoading || isLoadingSettings) && <LoadingSpinner />}
        {(shopError || settingsError) && 
          <ShowErrorModal
            title="Edit Store"
            error="Network Error"
            onClose={onClose}
          />
        }  
        {shopDetails && settings && categories && (
          <Formik
            initialValues={{
              [shopProperties.shopName]: shopDetails.shopName ?? '',
              [shopProperties.shopDescription]: shopDetails.description ?? '',
              [shopProperties.shopAddress]: shopDetails.address ?? '',
              addressOverride: shopDetails.addressOverride ?? '',
              [shopProperties.geoData]: shopDetails.geoData ?? {},
              [shopProperties.shopEmail]: shopDetails.email ?? '',
              [shopProperties.shopCategories]: shopDetails.categories ?? [],
              [shopProperties.shopOpenDays]: keys(
                pickBy(shopDetails.schedule, (day) => day.isOpen)
              ),

              // open hours
              [shopProperties.mondayOpen]: shopDetails.schedule.monday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.mondayClose]: shopDetails.schedule.monday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.tuesdayOpen]: shopDetails.schedule.tuesday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.tuesdayClose]: shopDetails.schedule.tuesday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.wednesdayOpen]: shopDetails.schedule.wednesday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.wednesdayClose]: shopDetails.schedule.wednesday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.thursdayOpen]: shopDetails.schedule.thursday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.thursdayClose]: shopDetails.schedule.thursday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.fridayOpen]: shopDetails.schedule.friday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.fridayClose]: shopDetails.schedule.friday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.saturdayOpen]: shopDetails.schedule.saturday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.saturdayClose]: shopDetails.schedule.saturday.close ?? DEFAULT_CLOSE_TIME,
              [shopProperties.sundayOpen]: shopDetails.schedule.sunday.open ?? DEFAULT_OPEN_TIME,
              [shopProperties.sundayClose]: shopDetails.schedule.sunday.close ?? DEFAULT_CLOSE_TIME,

              [shopProperties.shopWebsite]: shopDetails.website ?? '',
              [shopProperties.onlineShopUrl]: shopDetails.onlineShopUrl ?? '',
              [shopProperties.shopPhone]: shopDetails.phone ?? '',
              [shopProperties.shopFacebook]: shopDetails.facebook ?? '',
              [shopProperties.shopInstagram]: shopDetails.instagram ?? '',
              [shopProperties.shopTwitter]: shopDetails.twitter ?? '',
              [shopProperties.isStoreEnabled]: shopDetails.isStoreEnabled ? ['isStoreEnabled'] : [],
              showProducts: shopDetails.showProducts ? ['showProducts'] : [],
              [shopProperties.isChatEnabled]: shopDetails.isChatEnabled ? ['isChatEnabled'] : [],
              [shopProperties.showChatMessage]: shopDetails.showChatMessage ? ['showChatMessage'] : [],
              [shopProperties.chatMessage]: shopDetails.chatMessage ?? '',
              volunteerTitle: shopDetails.volunteerTitle ?? '',
              volunteerMessage: shopDetails.volunteerMessage ?? '',
              image: getImageFromServer(shopDetails.logotype)
            }}
            validationSchema={Yup.object({
              [shopProperties.shopName]: Yup.string()
                .max(255, 'Too long, max 255 characters')
                .required('Store name is required'),
              [shopProperties.shopDescription]: Yup.string().max(
                500,
                'Too long, max 500 characters'
              ),
              [shopProperties.shopAddress]: Yup.string()
                .max(255, 'Too long, max 255 characters')
                .required('Store Address is required'),
              addressOverride: Yup.string().max(255, 'Too long, max 255 characters'),
              [shopProperties.shopWebsite]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.onlineShopUrl]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.shopFacebook]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.shopTwitter]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.shopInstagram]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.shopPhone]: Yup.string().max(
                255,
                'Too long, max 255 characters'
              ),
              [shopProperties.shopOpenDays]: Yup.array().min(
                1,
                'Your store should be open at least one day per week'
              ),
              // [shopProperties.shopEmail]: Yup.string()
              //   .email('Invalid email address')
              //   .required('Email is required'),
              volunteerTitle: Yup.string().max(255, 'Too long, max 255 characters'),
              volunteerMessage: Yup.string().max(255, 'Too long, max 255 characters')
            })}
            onSubmit={submitStoreChanges}
          >
            {({ values, setFieldValue, errors, isValid }) => (
              <Form>
                <Headline>Edit Store</Headline>
                <ShopOperationHoursModal
                  isOpen={!isNull(selectedDay)}
                  setSelectedDay={setSelectedDay}
                  selectedDay={selectedDay}
                />
                <InputsWrapper>
                  <ImageUploading
                    value={values.image}
                    onChange={(image) => {
                      setFieldValue(
                        'image',
                        getImageBase64({ source: image })
                      )
                      setImages(image)
                      setLogoRemoved(false)
                    }}
                    acceptType={['jpg', 'gif', 'png']}
                    maxFileSize={4000000}
                    dataURLKey="data_url"
                  >
                    {({ onImageUpload }) => (
                      <ShopDetailsHead>
                        <ShopNameAndLogo>
                          {logoRemoved ? (
                            <EmptyShopLogo error={!isEmpty(errors)} size={72}>
                              <Store size={24} color={theme.colors.skyNeutral3} />
                            </EmptyShopLogo>
                          ) : (
                            <ShopLogo
                              errors={!isEmpty(errors)}
                              source={images}
                              logoUrl={shopDetails?.logotype}
                              size={72}
                            />
                          )}
                        </ShopNameAndLogo>

                        <ShopNameAndAddress>
                          <Typography
                            variant="headingS"
                            color={theme.colors.skyNeutral_2}
                          >
                            Image
                          </Typography>
                          <Typography
                            variant="textXS"
                            color={theme.colors.skyNeutral2}
                          >
                            File type (JPG, JPEG, or PNG) max. 4MB
                          </Typography>

                          <ImageRestrictions variant="TextS">
                            {errors
                              ? errors.maxFileSize &&
                                'Image size is too big. Please pick image less then 4MB'
                              : '(JPG, JPEG, or PNG, max. 4mb) '}
                          </ImageRestrictions>

                          <ImageButtonWrap>
                            <Button
                              variant={BUTTON_VARIANTS.PRIMARY}
                              type="button"
                              size={BUTTONS_SIZES.SMALL}
                              onClick={onImageUpload}
                            >
                              Upload
                            </Button>
                            <Button
                              variant={BUTTON_VARIANTS.SECONDARY}
                              type="button"
                              size={BUTTONS_SIZES.SMALL}
                              onClick={() => {
                                setFieldValue('image', '')
                                setLogoRemoved(true)
                              }}                              
                            >
                              Remove
                            </Button>
                          </ImageButtonWrap>


                        </ShopNameAndAddress>
                      </ShopDetailsHead>
                    )}
                  </ImageUploading>

                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName={shopProperties.shopName}
                    label="Store name"
                    placeholder="Enter Store Name"
                  />
                  <TextAreaInput
                    rows={5}
                    label="Description about your store"
                    placeholder="Describe your store"
                    inputName={shopProperties.shopDescription}
                  />
                  <SelectAddressInput inputName={shopProperties.shopAddress} />
                  { settings.isLocationOverrideVisible &&
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName="addressOverride"
                    label="Address override"
                    placeholder="Enter address override"
                  />
                  }
                  <TextInput
                    size={INPUT_SIZES.SMALL}
                    inputName={shopProperties.shopEmail}
                    label="Email"
                    placeholder="Enter email address"
                    disabled
                  />
                  <SelectManyInput
                    options={categories.map((category) => ({
                      value: category.id,
                      label: category.tagName
                    }))}
                    label="Category"
                    inputName={shopProperties.shopCategories}
                  />
                  <DividerLine />
                  <HoursWrapper>
                    <VStack>
                      <ShopDetailsHeadlineWrap>
                        <ShopDetailsHeadline>Hours Operational</ShopDetailsHeadline>
                        <ShopDetailsDescription>
                          Please select when your store is open
                        </ShopDetailsDescription>
                      </ShopDetailsHeadlineWrap>
                      <OpenHoursWrap>
                        {WEEK_DAYS.map((dayOfWeek) => (
                          <WorkingDayWrap key={dayOfWeek}>
                            <SwitchInput
                              inputName={shopProperties.shopOpenDays}
                              value={dayOfWeek}
                              label={capitalize(dayOfWeek)}
                            />
                            <EditWorkingHours
                              open={values[`${dayOfWeek}Open`]}
                              close={values[`${dayOfWeek}Close`]}
                              day={dayOfWeek}
                              isOpen={some(
                                values[shopProperties.shopOpenDays],
                                (dayShopIsOpened) => dayShopIsOpened === dayOfWeek
                              )}
                              setDay={setSelectedDay}
                            />
                          </WorkingDayWrap>
                        ))}
                      </OpenHoursWrap>

                      {errors[shopProperties.shopOpenDays] && (
                        <OperationalHoursErrorWrap>
                          <Typography
                            variant="textXS"
                            color={globalTheme.colors.skyNeutral1}
                          >
                            {errors[shopProperties.shopOpenDays]}
                          </Typography>
                        </OperationalHoursErrorWrap>
                      )}
                    </VStack>
                  </HoursWrapper>
                  <DividerLine />
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.shopWebsite}
                      label="Website (Optional)"
                      placeholder="Enter Website URL"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.onlineShopUrl}
                      label="Online Shop (Optional)"
                      placeholder="Enter Online Shop URL"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.shopPhone}
                      label="Phone Number (Optional)"
                      placeholder="Enter Phone Number"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.shopFacebook}
                      label="Facebook (Optional)"
                      placeholder="Enter Facebook URL"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.shopInstagram}
                      label="Instagram (Optional)"
                      placeholder="Enter Instagram URL"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.shopTwitter}
                      label="Twitter (Optional)"
                      placeholder="Enter Twitter URL"
                    />
                  </TextInputWrapper>

                  <SwitchWrap>
                    <SwitchInput
                      inputName={shopProperties.isStoreEnabled}
                      value="isStoreEnabled"
                      label="Store Enabled"
                    />
                  </SwitchWrap>
                  <SwitchWrap>
                    <SwitchInput
                      inputName="showProducts"
                      value="showProducts"
                      label="Show Products"
                    />
                  </SwitchWrap>
                  <SwitchWrap>
                    <SwitchInput
                      inputName={shopProperties.isChatEnabled}
                      value="isChatEnabled"
                      label="Chat Enabled"
                    />
                  </SwitchWrap>
                  <SwitchWrap>
                    <SwitchInput
                      inputName={shopProperties.showChatMessage}
                      value="showChatMessage"
                      label="Show Chat Message"
                    />
                  </SwitchWrap>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName={shopProperties.chatMessage}
                      label="Chat Message"
                      placeholder="Enter Chat Message"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="volunteerTitle"
                      label="Volunteer Title"
                      placeholder="Enter Volunteer Title"
                    />
                  </TextInputWrapper>
                  <TextInputWrapper>
                    <TextInput
                      size={INPUT_SIZES.SMALL}
                      inputName="volunteerMessage"
                      label="Volunteer Message"
                      placeholder="Enter Volunteer Message"
                    />
                  </TextInputWrapper>
                </InputsWrapper>
                {!isValid && (
                  <p>
                    Please check form for errors in: {keys(errors).join(', ')}
                  </p>
                )}
                {error && 
                  <ErrorWrap>
                    <Typography variant="textS" color="red">
                    {error}
                    </Typography>
                  </ErrorWrap>          
                }                
                <ButtonWrap>
                  <Button
                    error={!isValid}
                    type="submit"
                    variant={BUTTON_VARIANTS.PRIMARY}
                    size={BUTTONS_SIZES.MED}
                    isLoading={isUpdating}
                  >
                    Save
                  </Button>
                  <Button
                    type="button"
                    variant={BUTTON_VARIANTS.SECONDARY}
                    size={BUTTONS_SIZES.MED}
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                </ButtonWrap>
              </Form>
            )}
          </Formik>
        )}
      </ModalBody>
    </Modal>
  )
}

export default withTheme(EditShop)

EditShop.propTypes = {
  theme: PropTypes.object.isRequired, 
  shopId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

